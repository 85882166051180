.header {
    background-image: linear-gradient(
            rgb(112, 63, 160), rgba(203, 136, 222));

/*rgb(87, 28, 175), rgba(146, 97, 193, 0.75), rgba(203, 136, 222, 0.75));*/
    /*rgb(87, 28, 175), rgba(146, 97, 193, 0.75), rgba(203, 136, 222, 0.25), rgba(255,255,255,0));*/
/*    rgb(28, 58, 145), rgb(64, 94, 196), rgba(99, 172, 250, 0.5));*/
    /*background-color: #63308d;*/
    width: fit-content;
    min-width: 100%;
    /*margin-bottom: 5vh;*/
    /*padding: 1.5vh;*/
}

.nav {
    display: flex;
    margin: auto 1rem;
    white-space: normal;
}

/*.nav {*/
/*    display: flex;*/
/*}*/

.link, .active {
    padding: 1rem 1rem 0.5rem 1rem;
    color: white;
    text-transform: uppercase;
    font-size: 1.5rem;
    text-decoration: none;
}

/*.link {*/
/*    !*border-bottom: 5px solid #1c5fb0;*!*/
/*    background-color: gainsboro;*/
/*    font-weight: bold;*/
/*    color: #666666;*/
/*}*/

.link:last-child, .active:last-child {
    margin-left: auto;
}

.link:hover {
    background-color: #5f33a0;
    /*background-color: #1e208c;*/
    /*transform: scale(1.1);*/
    color: white;
    border-bottom: none;
}

.active {
    color: rgb(54, 20, 82);
    background-color: rgba(203, 136, 222, 0.5);
        /*font-weight: bold;*/
    /*background: rgba(203, 136, 222, 0.25);*/
}
