/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  /*font-family: 'Comfortaa', cursive;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background-image: linear-gradient(*/
  /*        rgb(31, 77, 169), !*rgb(97, 148, 224),*! rgba(255,255,255,0));*/
  border: none;
  background-repeat: repeat-x;
  min-height: fit-content;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/*h1 {*/
/*  color: white;*/
/*  margin: 5vh;*/
/*  padding: 5vh;*/
/*}*/

h1 {
  color: #361452;
  margin: 5vh;
  padding: 5vh;
}

.coinOutputs h3 {
  -webkit-background-clip: text;
  color: transparent;
}

.coinOutputs--green {
  background: linear-gradient(to bottom right, #003400, #00cd00);
}

.coinOutputs--white {
  /*background: linear-gradient(to bottom right, #d9d9d9, white);*/
}

.coinOutputs--violet {
  background: linear-gradient(to bottom, rgb(112, 63, 160), rgba(203, 136, 222));
}

.icon {
  color: rgb(78, 35, 120);
  margin: 1vh;
  padding: 1vh;
}

.icon:hover {
  /*background-color: #5f33a0;*/
  /*transform: scale(1.1);*/
  background-image: linear-gradient(
          rgb(112, 63, 160), rgba(203, 136, 222));
  color: white;
  border-bottom: none;
}

.active {
  color: rgb(54, 20, 82);
  background-color: rgba(203, 136, 222, 0.5);
  /*font-weight: bold;*/
  /*background: rgba(203, 136, 222, 0.25);*/
}

.button {
  border-radius: 20vw;
  /*background-image: linear-gradient(#617fee, #093ec4, #8299ee);*/
  /*color: white;*/
  /*background-color: #2629a7;*/
  padding: 1rem 1rem;
  margin: 1rem 1rem;
  width: 15rem;
  border: none;
}
textarea:focus, input:focus{
  outline: none;
}

button:disabled {
  background-color: #f3f3f3;
  border-color: lightgrey;
}

input{
  border-radius: 20vw;
  padding: 1rem 1rem;
  margin: 1rem 1rem;
  width: 15rem;
  border: none #666666;
}

.search {
  margin-top: 5vh;
}

.booking-input {
  padding: 0.5rem 0.5rem;
  border: solid gainsboro 1px;
  border-radius: 5rem;
  margin: 1rem 1rem;
}

/*.items-text {*/
/*  overflow: hidden;*/
/*  position: relative;*/
/*  height: 5rem;*/
/*}*/
/*.items-text:after {*/
/*  content: "";*/
/*  text-align: right;*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  right: 0;*/
/*  left: 0;*/
/*  height: 1.2em;*/
/*  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), white 100%);*/
/*  pointer-events: none;*/
/*  font-size: small;*/
/*}*/

.appPopover {
  display: inline-flex;
  font-size: xx-small;
}
